.banner_img_fit {
    height: 100vh;
    object-fit: cover;
    overflow: hidden;
}
.mt-10p {
    margin-top: 10%;
}
.logo {
    margin-bottom: 70px;
    width: 70%;
}
.footer-align {
    width: 100%;
    height: auto;
    padding: 12px 25px;
}
.footer-align span {
    margin-right: 10px;
}
.error-text {
    color: red;
    margin-bottom: 0;
}
.ant-upload-list-item-container .ant-upload-list-item.ant-upload-list-item-done,
.ant-upload-list-item-container .ant-upload-list-item.ant-upload-list-item-undefined {
    position: relative;
    height: 40px;
    margin-top: 5px;
    font-size: 15px;
    display: flex;
    align-items: center;
    transition: background-color 0.3s;
    padding: 9px 4px;
    color: #455560;
    max-width: 320px;
}
.ant-upload-list-item-container .ant-upload-list-item.ant-upload-list-item-done .ant-btn-icon svg,
.ant-upload-list-item-container .ant-upload-list-item.ant-upload-list-item-undefined .ant-btn-icon svg {
    color: red;
    font-size: 18px;
}
.ant-btn {
    white-space: nowrap;
}
.ant-upload-list-item-container .ant-upload-list-item-name {
    color: #21b573 !important;
}
.ant-upload-list-item-container .ant-upload-list-item:hover {
    background-color: rgba(0, 0, 0, 0.1);
}
.ant-table-thead > tr > th {
    overflow-wrap: normal !important;
}
.ant-select-multiple {
    line-height: 38px;
}
/* ButtonStyles.css */
.button-acknowledge {
    background-color: #87ceeb;
    color: #333; /* Assuming "text-dark" is a darker text color */
}
.button-certificate {
    background-color: #ffdbbb;
    color: #333;
}
.button-receipt {
    background-color: #e0b0ff;
    color: #333;
    margin: 0 10px;
}
.float-right {
    float: right;
}
/* Add this CSS in your CSS file or inline */
.custom-card {
    margin-bottom: 20px;
    padding: 30px 15px;
    border-radius: 5px;
    background-color: #f9f9f9;
}
.custom-card h3 {
    margin-bottom: 10px;
    color: #21b573;
    font-size: 20px;
}
.custom-card p {
    margin: 0;
    font-size: 16px;
    line-height: 1.6;
    max-width: 1200px;
}
.camera-collapsed {
    margin-top: 0px;
    max-height: 0;
    opacity: 0;
    overflow: hidden;
    transition:
        max-height 0.5s ease,
        opacity 0.5s ease,
        margin-top 0.5s ease;
}
.camera-expanded {
    margin-top: 20px;
    max-height: 500px;
    opacity: 1;
    transition:
        max-height 0.5s ease,
        opacity 0.5s ease,
        margin-top 0.5s ease;
}
.premium-text {
    margin-top: 40px;
    color: #21b573;
    margin-bottom: 50px;
}
.premium-text span {
    font-weight: bold;
}
.custome-scrollable-container {
    max-height: 500px;
    overflow-y: auto;
}
.custome-scrollable-container::-webkit-scrollbar {
    width: 8px;
    transition: opacity 0.3s ease-in-out;
}
.custome-scrollable-container.hide-scrollbar::-webkit-scrollbar {
    opacity: 0;
}
.custome-scrollable-container::-webkit-scrollbar-track {
    background: #f0f0f0;
    border-radius: 10px;
}
.custome-scrollable-container::-webkit-scrollbar-thumb {
    background-color: #ade7cc;
    border-radius: 10px;
    border: 2px solid #f0f0f0;
}
.custome-scrollable-container::-webkit-scrollbar-thumb:hover {
    background-color: #ade7cc;
}
.ant-card-body {
    padding-bottom: 0;
}
.nav-header {
    display: flex;
    justify-content: flex-start;
    width: 270px;
}
.nav-header .logo {
    width: 180px !important;
}
.chooseFile button {
    padding: 50px 40px;
}
.chooseFile .ant-modal-content {
    padding-top: 50px;
}
.desktop-role {
    display: inline;
}
.mobile-prefix {
    display: none;
}
.mob-hide {
    display: block;
}

@media (max-width: 991.55px) {
    .css-1vsxyv9 {
        height: 60px;
        line-height: 140%;
        margin: 0 0 !important;
    }
    .nav-header {
        display: flex;
        justify-content: flex-start;
        width: 0px;
    }
    .text-header {
        line-height: 120%;
        width: 270px;
    }
    .mobile-prefix {
        display: inline; /* Show prefix on mobile screens */
    }

    .desktop-role {
        display: none; /* Hide the regular name on mobile screens */
    }
    .mob-hide {
        display: none !important;
    }
    .breadcrum {
        margin-left: 12px;
        z-index: 1;
    }
    .content {
        margin-left: 35px;
    }
}
@media (max-width:500px) {
    .sm-w-90 {
        width: 90% !important;
    }
    .sm-mb-40 {
        margin-bottom: 40px;
    }
}
@media (max-width: 767.55px) {
    .footer-align {
        display: none !important;
    }
}


