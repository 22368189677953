/* body,
html {
    margin: 0;
    padding: 0;
    font-family: Arial, sans-serif;
    line-height: 1.6;
    color: #333;
    font-family: "Noto Serif", serif;
    font-weight: 400;
} */

/* Body style to take the full screen */
/* body {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #cfd6db1a;
    flex-direction: column;
} */

/* Header Styling */

/* @font-face {
    font-family: "Montserrat";
    src:
        url("/public/fonts/Montserrat-VariableFont_wght.woff2") format("woff2"),
        url("/public/fonts/Montserrat-Italic-VariableFont_wght.woff") format("woff");
}

@font-face {
    font-family: "Noto Serif";
    src:
        url("/public/fonts/NotoSerif-VariableFont_wdth,wght.woff2") format("woff2"),
        url("/public/fonts/NotoSerif-Italic-VariableFont_wdth,wght.woff") format("woff");
} */

/* @font-face {
    font-family: "Playfair Display";
    src:
        url("/fonts/PlayfairDisplay-Regular.woff2") format("woff2"),
        url("/fonts/PlayfairDisplay-Regular.woff") format("woff");
} */

.receipt-body {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #cfd6db1a;
    flex-direction: column;
    font-family: "Noto", serif;
}
.header {
    background-color: #c11717;
    color: white;
    padding: 30px 30px;
    display: flex;
    justify-content: flex-start; /* Aligns content to the left */
    align-items: center;
    margin: -30px;
    margin-bottom: 20px;
}

.header img {
    width: 130px; /* Set the width of the logo */
    height: auto;
}

.receipt-container {
    background-color: white;
    /* margin: 70px; */
    margin-bottom: 0;
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    box-shadow: 0 0 15px rgba(0, 0, 0, 0.1);
    padding: 40px;
    box-sizing: border-box;
}

/* Specific styles for the elements */
.customer-name {
    font-size: 22px;
    margin-bottom: 30px;
    color: #333;
}

.success-section {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 30px;
}

.check-icon {
    background-color: #3fbb3f;
    margin-right: 15px;
    width: 40px;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
}

.fa-check {
    color: white;
    font-size: 20px;
}

.payment-success {
    font-size: 24px;
    color: green;
}

.welcome-message {
    line-height: 1.8;
    color: #4f4e4e;
    font-size: 18px;
}

.note {
    font-size: 20px;
    color: red;
    margin-top: 30px;
}

.section-content {
    background-color: rgb(245, 251, 255);
    font-size: 18px;
    margin-left: -40px;
    padding-left: 40px;
    border-radius: 16px;
}
.section-content .section-header {
    font-weight: bold;
    padding: 20px 16px;
    border-bottom: 1px solid rgb(228, 234, 234);
    margin-left: -40px;
    padding-left: 40px;
    margin-bottom: 0;
    margin-top: 0;
}
.section-content .transaction-row {
    padding: 20px 16px;
    border-bottom: 1px solid rgb(228, 234, 234);
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin-left: -40px;
    padding-left: 40px;
}
.section-content .transaction-row:last-child {
    border-bottom: none;
}
.section-content .transaction-row .label {
    width: 40%;
}
.section-content .transaction-row .value {
    width: 60%;
    font-weight: bold;
}

.customer-details {
    font-size: 18px;
    margin-left: -40px;
    padding-left: 40px;
    border-radius: 16px;
}
.customer-details .section-title {
    font-weight: bold;
    padding: 20px 16px;
    border-bottom: 1px solid rgb(228, 234, 234);
    margin-left: -40px;
    padding-left: 40px;
    margin-bottom: 0;
    margin-top: 0;
}
.customer-details .customer-row {
    padding: 20px 16px;
    border-bottom: 1px solid rgb(228, 234, 234);
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin-left: -40px;
    padding-left: 40px;
}

.customer-details .customer-row:last-child {
    border-bottom: none;
}
.customer-details .customer-row .icon {
    width: 5%;
}
.customer-details .customer-row .label {
    width: 35%;
}
.customer-details .customer-row .value {
    width: 60%;
    font-weight: bold;
}

/* footer */

.footer {
    background-color: #333;
    color: white;
    display: flex;
    justify-content: space-around;
    align-items: center;
    flex-wrap: wrap;
    width: 100%;
    font-size: 16px;
    /* margin-bottom: 70px; */
}

.footer div {
    margin: 10px 0;
    text-align: center;
}

.footer .icon {
    margin-right: 10px;
    font-size: 18px;
    color: #4caf50; /* Green color for icons */
}

.footer a {
    color: white;
    text-decoration: none;
    font-weight: bold;
    font-size: 16px;
    transition: color 0.3s;
}

.footer a:hover {
    color: #4caf50;
}

.footer .contact,
.footer .email,
.footer .website {
    display: flex;
    justify-content: center;
    align-items: center;
}

.footer .contact i,
.footer .email i,
.footer .website i {
    margin-right: 10px;
}
.title-wish {
    font-size: 22px;
    margin-bottom: 30px;
    color: #333;
    text-align: left !important;
    align-items: flex-start;
    font-family: "Noto", serif;
}
.description {
    text-align: left;
    font-family: "Noto", serif;
    font-size: 14px;
}
